import { get, post } from "./request.service";
import { config } from "../utils/constants";
import { LoginRequest, Session } from "../models";
import { formatFiscalCode } from "../utils/formats";

export async function performLogin(loginRequest: LoginRequest): Promise<Session> {
    const response = await post(`${config.url}/login`, {
        ...loginRequest,
        username: loginRequest.username
    });
    if (!response) {
        throw new Error("Error while performing login");
    }
    return response as Session;
}

export async function performPreLogin(loginRequest: LoginRequest): Promise<Session> {
    const response = await post(`${config.url}/pre-login`, {
        ...loginRequest,
        username: loginRequest.username
    });
    if (!response) {
        throw new Error("Error while performing login");
    }
    return response as Session;
}

export async function checkToken(session?: Session): Promise<{ status: "valid" | "expiring"; token?: string }> {
    return get(`${config.url}/check-token`, session);
}
