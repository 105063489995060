import { Crop } from "react-image-crop";
import removeAccents from "remove-accents";

export const comparator = function (obj: any, text: any): boolean {
    if (obj && text && typeof obj === "object" && typeof text === "object") {
        for (const objKey in obj) {
            if (objKey.charAt(0) !== "$" && objKey.hasOwnProperty(obj) && comparator(obj[objKey], text[objKey])) {
                return true;
            }
        }
        return false;
    }
    text = removeAccents("" + text).toLowerCase();
    return (
        removeAccents("" + obj)
            .toLowerCase()
            .indexOf(text) > -1
    );
};

export const deepSearch = (obj: any, text: any): boolean => {
    if (typeof text == "string" && text.charAt(0) === "!") {
        return !deepSearch(obj, text.substr(1));
    }
    if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
            if (deepSearch(obj[i], text)) {
                return true;
            }
        }
        return false;
    }
    switch (typeof obj) {
        case "boolean":
        case "number":
        case "string":
            return comparator(obj, text);
        case "object":
            switch (typeof text) {
                case "object":
                    return comparator(obj, text);
                default:
                    for (let objKey in obj) {
                        if (objKey.charAt(0) !== "$" && deepSearch(obj[objKey], text)) {
                            return true;
                        }
                    }
                    break;
            }
            return false;
        default:
            return false;
    }
};

export function getNamePlaceholder(name = ""): string {
    const names = name.split(" ");
    return names.map(n => n.charAt(0)).join("");
}

export function setTime(date: Date, hours: number, minutes: number, seconds: number): Date {
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function setDate(day: number, month: number, year: number): Date {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
}

export function setDateTime(
    day: number,
    month: number,
    year: number,
    hours: number,
    minutes: number,
    seconds: number
): Date {
    const date = new Date();
    date.setFullYear(year, month, day);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
}

export function calculateAge(birth: Date) {
    const diff = Date.now() - birth.getTime();
    const ageDiff = new Date(diff);

    return Math.abs(ageDiff.getUTCFullYear() - 1970);
}

function daysBetweenDates(startDate: Date, endDate: Date): number {
    const oneDay = 1000 * 60 * 60 * 24;

    const start = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());
    const end = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    return (start - end) / oneDay;
}

export function checkDateComposite(compositeExists: boolean, composite?: Date): string {
    if (!compositeExists) {
        return "nonexistent";
    }
    if (!composite) {
        return "no-date";
    }
    const days = daysBetweenDates(new Date(composite), new Date());
    if (days <= 180) return "up-to-date";
    if (days > 180 && days < 360) return "outdated";
    if (days > 360) return "expired";
    return "";
}

export function validateEmail(email: string): boolean {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
}

export function validateFiscalCode(fiscalCodeToValidate: string): boolean {
    let sum;
    let remaining;
    sum = 0;
    const fiscalCode = fiscalCodeToValidate.replace(/\D/g, "");
    if (fiscalCode.length !== 11 || !Array.from(fiscalCode).filter(e => e !== fiscalCode[0]).length) {
        return false;
    }

    for (let i = 1; i <= 9; i++) {
        sum = sum + parseInt(fiscalCode.substring(i - 1, i)) * (11 - i);
    }
    remaining = (sum * 10) % 11;

    if (remaining === 10 || remaining === 11) remaining = 0;
    if (remaining !== parseInt(fiscalCode.substring(9, 10))) return false;

    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum = sum + parseInt(fiscalCode.substring(i - 1, i)) * (12 - i);
    }
    remaining = (sum * 10) % 11;

    if (remaining === 10 || remaining === 11) {
        remaining = 0;
    }
    return remaining === parseInt(fiscalCode.substring(10, 11));
}

export function cuilValidator(cuilFormatted: string): boolean {
    const cuil = cuilFormatted.replace(/\D/g, "");
    if (cuil.length !== 11) {
        return false;
    }

    const [checkDigit, ...rest] = cuil.split("").map(Number).reverse();

    const total = rest.reduce((acc, cur, index) => acc + cur * (2 + (index % 6)), 0);

    const mod11 = 11 - (total % 11);

    if (mod11 === 11) {
        return checkDigit === 0;
    }

    if (mod11 === 10) {
        return false;
    }

    return checkDigit === mod11;
}

export const getCroppedImg = async (
    imageSrc: string,
    crop: Crop,
    width: number,
    height: number,
    resize: boolean = true
): Promise<{ blob: Blob; base64: string }> => {
    const createImage = (url: string): Promise<HTMLImageElement> =>
        new Promise((resolve, reject) => {
            const image = new Image();
            image.addEventListener("load", () => resolve(image));
            image.addEventListener("error", error => reject(error));
            image.setAttribute("crossOrigin", "anonymous");
            image.src = url;
        });
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / width;
    const scaleY = image.naturalHeight / height;
    const ctx = canvas.getContext("2d");

    const finalWidth = resize ? crop.width! : crop.width! * scaleX;
    const finalHeight = resize ? crop.height! : crop.height! * scaleY;

    canvas.width = finalWidth;
    canvas.height = finalHeight;

    ctx!.drawImage(
        image,
        crop.x! * scaleX,
        crop.y! * scaleY,
        crop.width! * scaleX,
        crop.height! * scaleY,
        0,
        0,
        finalWidth,
        finalHeight
    );
    return new Promise((resolve, reject) => {
        const base64 = canvas.toDataURL();
        const blob = dataURLtoBlob(base64);
        if (blob) {
            resolve({ blob, base64 });
        } else {
            reject(new Error("Could not crop image"));
        }
    });
};

function dataURLtoBlob(dataurl: string) {
    const [header, data] = dataurl.split(",");
    const [, mime] = header.match(/:(.*?);/)!;
    const bstr = atob(data);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}

export function hiphenizeName(name: string): string {
    return removeAccents(name).toUpperCase().split(" ").join("-");
}
